<template>
  <div class="identitydata">
    <van-nav-bar
      :title="$t('IDENTITYDATA')"
      @click-left="onClickLeft"
    >
    <van-icon color="#fff" size="25" slot="left" name="arrow-left" />
    </van-nav-bar>
    <div class="tips">{{$t('FILLINTRUEINFORMATION')}}</div>
    <div class="container">
      <van-field :readonly="stopSub" v-model="name" :placeholder="$t('PLEAINPUTNAME')" :label="$t('NAME')   " />
      <van-field :readonly="stopSub" v-model="IDcardNumber" :placeholder="$t('PLEAINPUTID')" type="number" :label="$t('IDCARDNUMBER')" />
    </div>
    <div class="tips">*{{$t('UPLOADIDPROTO')}}</div>
    <div class="uploadFile">
      <div class="uploadFileLeft">
        <van-uploader :after-read="afterRead" v-model="fileListFrontID" max-count="1" image-fit="contain" name="FrontID" >
          <div class="uplopadArea">
            <!-- <img src="./img/alterPassword.png" class="uploadAreaImg" alt="" srcset=""> -->
            <van-icon name="photo-o" size="80" color="#e1e1e1" />
            <div class="uplopadAreaText">Front of my ID card</div>
          </div>
        </van-uploader>
      </div>
      <div class="uploadFileRight">
        <van-uploader :after-read="afterRead" v-model="fileListSideID" max-count="1" image-fit="contain" name="SideID" >
          <div class="uplopadArea">
            <!-- <img src="./img/alterPassword.png" class="uploadAreaImg" alt="" srcset=""> -->
            <van-icon name="photo-o" size="80" color="#e1e1e1" />
            <div class="uplopadAreaText">Back</div>
          </div>
        </van-uploader>
      </div>
    </div>
    <div class="subbox">
      <van-button :disabled="stopSub" @click="sub" class="sub" color="#21A0FF" type="primary" block>{{$t('SUBMIT')}}</van-button>
    </div>
  </div>
</template>

<script>
import { get, save, myInformation } from '@/api/my'
import { uploadPhoto } from '@/api/wallet'
import { Toast, Dialog } from 'vant'
import './css/my.css'
export default {
  name: 'identitydata',
  data () {
    return {
      name: null,
      IDcardNumber: null,
      fileListFrontID: [],
      fileListSideID: [],
      stopSub: true
    }
  },
  async created () {
    await this.getmyInformation()
    await this.loadget()
  },
  methods: {
    getMainHost () {
      const key = `mh_${Math.random()}`
      const keyR = new RegExp(`(^|;)\\s*${key}=12345`)
      const expiredTime = new Date(0)
      const domain = document.domain
      const domainList = domain.split('.')

      const urlItems = []
      // 主域名一定会有两部分组成
      urlItems.unshift(domainList.pop())
      // 慢慢从后往前测试
      while (domainList.length) {
        urlItems.unshift(domainList.pop())
        const mainHost = urlItems.join('.')
        const cookie = `${key}=${12345};domain=.${mainHost}`

        document.cookie = cookie

        // 如果cookie存在，则说明域名合法
        if (keyR.test(document.cookie)) {
          document.cookie = `${cookie};expires=${expiredTime}`
          return mainHost
        }
      }
    },
    async afterRead (file, detail) {
      const formData = new FormData()
      formData.append('picture', file.file)
      const { data } = await uploadPhoto(formData)
      if (data.code === 200) {
        this['fileList' + detail.name][0].url = data.data
      }
      if (data.code === 500) {
        Toast(data.message)
      }
    },
    async loadget () {
      const { data } = await get()
      if (data.code === 200) {
        this.name = data.data.name
        this.IDcardNumber = data.data.idCardNo
        if (data.data.idFrontUrl) {
          this.fileListFrontID[0] = { url: `https://file.${this.getMainHost()}/${data.data.idFrontUrl}` }
        }
        if (data.data.idBackUrl) {
          this.fileListSideID[0] = { url: `https://file.${this.getMainHost()}/${data.data.idBackUrl}` }
        }
      }
      // console.log(data)
    },
    onClickLeft () {
      this.$router.go(-1)
    },
    async sub () {
      if (this.IDcardNumber && this.name && this.fileListFrontID.length > 0 && this.fileListSideID.length > 0) {
        const { data } = await save({
          idBackUrl: this.fileListSideID[0].url,
          idCardNo: this.IDcardNumber,
          idFrontUrl: this.fileListFrontID[0].url,
          name: this.name
        })
        if (data.code === 200) {
          this.stopSub = true
          Dialog.alert({
            message: this.$t('SUBMITSUCCEED'),
            confirmButtonText: this.$t('CONFIRM')
          }).then(() => {
            // on close
          })
          // Toast(data.message)
          setTimeout(() => {
            this.$router.go(-1)
          }, 3000)
        }
        if (data.code === 500) {
          Dialog.alert({
            message: data.message,
            confirmButtonText: this.$t('CONFIRM')
          }).then(() => {
            // on close
          })
        }
      } else {
        Toast(this.$t('FILLINALL'))
      }
    },
    async getmyInformation () {
      const { data } = await myInformation()
      if (data.data.identity) {
        // console.log(data.data.identity)
        this.stopSub = true
      } else {
        // console.log(false)
        this.stopSub = false
      }
      // console.log(data)
    }
  }
}
</script>

<style scoped>
.identitydata >>> .van-nav-bar__content {
  background-color: #21A0FF;
  /* color: #fff; */
}
.identitydata >>> .van-nav-bar__title {
  color: #fff;
}
.img {
  width: 100%;
  height: 100%;
}
.del {
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  background: rgba(0, 0, 0, 0.5);
  border-bottom-left-radius: 50px;
  text-align: center;
  color: #fff;
  font-size: 15px;
  line-height: 30px;
  text-indent: .5em;
}
.showimg {
  position: relative;
  width: 100%;
  height: 100%;
}
.tips {
  text-align: center;
  padding: 10px;
  font-size: 13px;
  color: #666;
}
.container {
  box-sizing: border-box;
  padding: 0 10px;
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  background-color: #fff;
}
.IDUploadbox {
  box-sizing: border-box;
  padding: 3px;
  height: 200px;
  background-color: #fff;
}
.UploadArea {
  width: 367px;
  height: 190px;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
}
.cameraBox {
  margin: 0 auto;
  margin-top: 40px;
  width: 70px;
  height: 70px;
}
.cameraImg {
  width: 100%;
  height: 100%;
}
.UploadInstructions {
  margin: 0 auto;
  width: 150px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  color: #fff;
  font-size: 14px;
  background: #9cf;
  opacity: .5;
  padding: 0 9px;
  margin-top: 20px;
  border-radius: 8px;
}
.uploadFile {
  height: 180px;
  display: flex;
  background-color: #fff;
}
.uploadFile >>> .van-uploader__preview-image {
  width: 150px;
  height: 150px;
}
.uploadFile >>> .van-uploader__preview-delete {
  width: 30px;
  height: 30px;
}
.uploadFile .van-uploader__preview-delete-icon {
  font-size: 33Px;
}
.uploadFileLeft {
  flex: 1;
  display: flex;
  justify-content: center;
}
.uploadFileRight {
  flex: 1;
  display: flex;
  justify-content: center;
}
.uplopadArea {
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.uplopadAreaText {
  font-size: 14Px;
  color: #7d7c7c;
}
.subbox {
  padding: 20px;
}
.sub {
  font-size: 18px;
}
</style>
